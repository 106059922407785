import { nextTick } from 'vue';
import { defineNuxtRouteMiddleware, useNuxtApp } from '#imports';

export default defineNuxtRouteMiddleware((to: { fullPath: string }) => {
  if (import.meta.dev) {
    return;
  }
  const { $posthog } = useNuxtApp();
  const posthog = $posthog();
  nextTick(() => {
    posthog.capture('$pageview', {
      current_url: to.fullPath,
    });
  });
});
