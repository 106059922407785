<script lang="ts" setup>
import {
  defineOgImageComponent,
  onMounted,
  ref,
  useRoute,
  useRouter,
  useDialog,
} from '#imports';
import { useWindowSize } from '@vueuse/core';

const route = useRoute();
const router = useRouter();

defineOgImageComponent('Default', {
  title: 'Discover your next adventure',
  description: 'Explore curated lists from local experts and global creators',
  imagePath: '/media/videos/5-poster.jpg',
});

const { openDialog } = useDialog();
const subscriptionSuccessful = ref(false);
const showConfetti = ref(false);

const celebrate = async () => {
  showConfetti.value = true;
};

const { width: viewPortWidth, height: viewPortHeight } = useWindowSize();

const confettiColors = [
  'var(--color-green-400)',
  'var(--color-rose-400)',
  'var(--color-indigo-400)',
  'var(--color-orange-400)',
  'var(--color-green-200)',
  'var(--color-rose-200)',
  'var(--color-indigo-200)',
  'var(--color-orange-200)',
  'var(--color-green-800)',
  'var(--color-rose-100)',
  'var(--color-indigo-700)',
  'var(--color-orange-300)',
];

onMounted(() => {
  // check if redirect from successful after stripe checkout
  if (route.query.payment === 'success') {
    openDialog('MPCheckEmail', {
      email: 'example@example.com',
      tier: route.query.tier,
    });
    celebrate();
    // setTimeout(() => {
    //   subscriptionSuccessful.value = false;
    // }, 5000);
    // router.replace({ query: undefined });
  }
});
</script>

<template>
  <div>
    <NuxtLoadingIndicator />
    <div class="flex-wrapper">
      <MPHeader />
      <NuxtPage />
      <MPFooter class="footer"/>
    </div>

    <MPMessage v-if="subscriptionSuccessful" version="success" class="subscription-success">
      <h4>Payment successfully processed.</h4>
      <p>Thank you for your support!</p>
    </MPMessage>
    <MPDialog />
    <MPToast />
    <MPConfetti
      v-if="showConfetti"
      :colors="confettiColors"
      :duration="4000"
      :particleCount="300"
      :force="0.8"
      :stageHeight="viewPortHeight"
      :stageWidth="viewPortWidth"
      class="confetti"
    />
  </div>
</template>

<style lang="postcss" scoped>
@import "@/assets/css/breakpoints.pcss";

.confetti {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 10%;
  left: 50%;
  scrollbar-width: none;
}

.subscription-success {
  position: fixed;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  min-width: 26rem;
  height: fit-content;
  background-color: var(--color-green-50);
  color: var(--color-green-900);
  z-index: 100;
  text-align: center;
  z-index: 9999;
  h4 {
    margin: 0.5rem;
  }
}

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }
}
.flex-wrapper:deep(header) {
  width: 100%;
}
.footer {
  margin-top: auto;
}
</style>
