import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_eslint@9.23.0_rollup@4.38.0_typescript@5.8.2_vite@6.2.4/node_modules/nuxt/dist/pages/runtime/validate.js";
import metrics_45global from "/opt/buildhome/repo/app/middleware/metrics.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_eslint@9.23.0_rollup@4.38.0_typescript@5.8.2_vite@6.2.4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  metrics_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/buildhome/repo/app/middleware/auth.ts")
}