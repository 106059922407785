import { loadStripe } from '@stripe/stripe-js/pure';
import { useRuntimeConfig, defineNuxtPlugin } from '#imports';

const initStripe = () => {
  const config = useRuntimeConfig();
  if (import.meta.env.DEV) {
    loadStripe.setLoadParameters({
      advancedFraudSignals: false,
    });
  }
  return loadStripe(config.public.stripePublicKey, {
    betas: ['custom_checkout_beta_5'],
  });
};

export default defineNuxtPlugin({
  name: 'stripe',
  parallel: true,
  async setup(nuxtApp) {
    const stripe = initStripe();
    nuxtApp.provide('stripe', stripe);
  },
});
