import type { Tables } from '@/types/database.types.ts';
import * as Sentry from '@sentry/nuxt';
import type { AuthUser } from '@supabase/supabase-js';
import {
  reloadNuxtApp,
  useNuxtApp,
  useRouter,
  useState,
  useSupabaseClient,
  useSupabaseUser,
} from '#imports';

interface User extends AuthUser {
  profile: Tables<'profiles'> | null;
  user: AuthUser | null;
  subscriptions: Tables<'platform_subscriptions'> | null;
  hasActiveSubscription: boolean | null;
  isActiveSupporter: boolean | null;
  supports: Tables<'creator_supporters'>[] | null;
  bookmarks: {
    maps: Tables<'map_bookmarks'>[];
    spots: Tables<'spot_bookmarks'>[];
  } | null;
}

export const useAuth = () => {
  const supabase = useSupabaseClient();
  const user = useSupabaseUser();
  const { auth } = supabase;
  const currentUser = useState<User | null>('currentUser', () => null);
  const showError = (error: string) => {
    console.error(error);
  };

  auth.onAuthStateChange((session) => {
    if (session === 'SIGNED_OUT') {
      resetUser();
    }
  });

  const resetUser = () => {
    currentUser.value = null;
  };

  const signUp = async (email: string, password: string) => {
    const { error } = await auth.signUp({ email, password });
    if (error) showError('auth.login.signupFailed');
  };

  const login = async (email: string, password: string) => {
    const { data, error } = await auth.signInWithPassword({ email, password });
    if (error) showError('auth.login.signupFailed');
    else await fetchCurrentUser();
  };

  const router = useRouter();
  const signOut = async () => {
    await auth.signOut();
    resetUser();
    router.push('/');
    reloadNuxtApp();
  };

  const fetchSubscriptions = async () => {
    if (!currentUser.value || !currentUser.value.user) return;
    const { data, error } = await supabase
      .from('platform_subscriptions')
      .select('*')
      .eq('user_id', currentUser.value.user.id)
      .eq('status', 'active')
      .order('created_at', { ascending: false });

    if (error) showError('Could not fetch subscriptions');

    const hasPlatformSubscription = data && data.length > 0;

    currentUser.value = {
      ...currentUser.value,
      hasActiveSubscription:
        hasPlatformSubscription ||
        currentUser.value?.isActiveSupporter ||
        false,
      subscriptions: data as Tables<'platform_subscriptions'>[],
    };
  };

  const fetchSupports = async () => {
    if (!currentUser.value || !currentUser.value.user) return;
    const { data, error } = await supabase
      .from('creator_supporters')
      .select('*')
      .eq('user_id', currentUser.value.user.id)
      .eq('status', 'active')
      .order('created_at', { ascending: false });

    if (error) showError('Could not fetch supports');

    const isSupporter = data && data.length > 0;

    currentUser.value = {
      ...currentUser.value,
      hasActiveSubscription:
        isSupporter || currentUser.value?.hasActiveSubscription || false,
      isActiveSupporter: isSupporter,
      supports: data as Tables<'creator_supporters'>[],
    };
  };

  const fetchProfileInfo = async () => {
    if (!currentUser.value) return;
    const { data, error } = await supabase
      .from('profiles')
      .select('*')
      .eq('id', currentUser.value.user.id)
      .single();
    if (error) showError('Could not fetch profile info');
    currentUser.value = {
      ...currentUser.value,
      profile: data as Tables<'profiles'> | null,
    };
    return data;
  };

  const fetchMapBookmarks = async () => {
    if (!currentUser.value || !currentUser.value.user) return;
    const { data, error } = await supabase
      .from('map_bookmarks')
      .select('*')
      .eq('user_id', currentUser.value.user.id)
      .order('created_at', { ascending: false });
    if (error) showError('Could not fetch map bookmarks');
    currentUser.value = {
      ...currentUser.value,
      bookmarks: {
        ...currentUser.value.bookmarks,
        maps: data as Tables<'map_bookmarks'>[],
      },
    };
    return data;
  };

  const fetchSpotBookmarks = async () => {
    if (!currentUser.value || !currentUser.value.user) return;
    const { data, error } = await supabase
      .from('spot_bookmarks')
      .select('*')
      .eq('user_id', currentUser.value?.user.id)
      .order('created_at', { ascending: false });
    if (error) showError('Could not fetch spot bookmarks');
    currentUser.value = {
      ...currentUser.value,
      bookmarks: {
        ...currentUser.value.bookmarks,
        spots: data as Tables<'spot_bookmarks'>[],
      },
    };
    return data;
  };

  const identifyUser = () => {
    if (!currentUser.value || !currentUser.value.user) return;
    if (import.meta.dev) return;
    const { $posthog } = useNuxtApp();
    const posthog = $posthog();
    posthog.identify(currentUser.value.user.id, {
      email: currentUser.value.user.email,
      is_creator: currentUser.value.profile?.is_creator || false,
      is_subscriber: currentUser.value.hasActiveSubscription || false,
      is_supporter: currentUser.value.isActiveSupporter || false,
    });
    Sentry.setUser({
      id: currentUser.value.user.id,
      email: currentUser.value.user.email,
    });
  };

  const fetchCurrentUser = async () => {
    // Only update if the user exists and is different from current
    if (
      user.value &&
      (!currentUser.value || currentUser.value.id !== user.value.id)
    ) {
      // Initialize with basic auth data
      currentUser.value = {
        user: { ...user.value },
        profile: null,
        subscriptions: null,
        hasActiveSubscription: null,
        isActiveSupporter: null,
        supports: null,
        bookmarks: {
          maps: [],
          spots: [],
        },
      };

      // Then fetch additional data
      await fetchProfileInfo();
      await fetchMapBookmarks();
      await fetchSpotBookmarks();
      await fetchSupports();
      await fetchSubscriptions();
      identifyUser();
    }
  };

  return {
    currentUser,
    fetchCurrentUser,
    login,
    signUp,
    signOut,
    identifyUser,
    fetchSpotBookmarks,
    fetchMapBookmarks,
  };
};
