import { useSupabaseClient } from '#imports';

export const useStorage = () => {
  const supabase = useSupabaseClient();

  const formatedImageUrl = (
    src: string,
    width = 200,
    height = 200,
    quality = 75,
    resize = 'cover',
  ) => {
    if (src?.includes('api.mappr.com/storage/')) {
      return `${src.split('/').join('/').replace('/object/', '/render/image/')}?width=${width}&height=${height}&quality=${quality}&resize=${resize}`;
    }
    return src;
  };

  const listFiles = async (bucket: string, folder: string) => {
    const { data, error } = await supabase.storage.from(bucket).list(folder, {
      limit: 100,
    });
    if (error) throw error;
    return data;
  };

  const uploadFile = async (bucket: string, file: File, folder: string) => {
    const { data, error } = await supabase.storage
      .from(bucket)
      .upload(folder, file, {
        cacheControl: '3600',
        upsert: false,
      });
    if (error) throw error;
    return data;
  };

  const deleteFile = async (bucket: string, filePath: string) => {
    const { data, error } = await supabase.storage
      .from(bucket)
      .remove([filePath]);
    if (error) throw error;
    return data;
  };

  const deleteFiles = async (bucket: string, filePaths: string[]) => {
    const { data, error } = await supabase.storage
      .from(bucket)
      .remove(filePaths);
    if (error) throw error;
    return data;
  };

  const getFilePublicUrl = (bucket: string, filePath: string) => {
    const { data } = supabase.storage.from(bucket).getPublicUrl(filePath);
    return data.publicUrl;
  };

  function getMimeType(file: File, fallback = null) {
    const reader = new FileReader();
    return new Promise<string | null>((resolve) => {
      reader.onloadend = (e) => {
        if (e.target?.result instanceof ArrayBuffer) {
          const arr = new Uint8Array(e.target.result).subarray(0, 4);
          let header = '';
          for (let i = 0; i < arr.length; i++) {
            header += arr[i].toString(16);
          }
          switch (header) {
            case '89504e47':
              resolve('image/png');
              break;
            case '47494638':
              resolve('image/gif');
              break;
            case 'ffd8ffe0':
            case 'ffd8ffe1':
            case 'ffd8ffe2':
            case 'ffd8ffe3':
            case 'ffd8ffe8':
              resolve('image/jpeg');
              break;
            default:
              resolve(fallback);
          }
        } else {
          resolve(fallback);
        }
      };
      reader.readAsArrayBuffer(file.slice(0, 4));
    });
  }

  return {
    listFiles,
    uploadFile,
    deleteFile,
    deleteFiles,
    getFilePublicUrl,
    getMimeType,
    formatedImageUrl,
  };
};
