import { defineNuxtPlugin, useRoute } from '#imports';
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('page:finish', () => {
    const route = useRoute();
    if (route.params.mapId) return;
    if (import.meta.client && document.body.scrollTo) {
      document.body.scrollTo({ top: 0 });
    }
  });
});
