
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as authzZkVbuKUK0QY1UUSWhf25wQ2Osusvv193T6qkbWPfo4Meta } from "/opt/buildhome/repo/app/pages/auth.vue?macro=true";
import { default as indexBUquJ6FW9selPoMmXmiQ06xberjjtjDDexnQK6NGwH0Meta } from "/opt/buildhome/repo/app/pages/index.vue?macro=true";
import { default as indexIycRgvcg2fhkpcx23LdnpCdGkbaLe5_45A9nyQ_45soOL4MMeta } from "/opt/buildhome/repo/app/pages/studio/maps/index.vue?macro=true";
import { default as index81hg42RcriHmxPE9Lo_45qe_5yFu2A9JiDsjHTi_45dS4dwMeta } from "/opt/buildhome/repo/app/pages/studio/maps/[mapId]/index.vue?macro=true";
import { default as _91spotId_93hWy5VIkCq1_4519UBO6ntLyZvywacWaROu8klwZjfHzs8Meta } from "/opt/buildhome/repo/app/pages/studio/maps/[mapId]/spots/[spotId].vue?macro=true";
import { default as _91mapId_939x1V1W4YOqRGVSdF7kVD1mCmSFI5gky5FxTosZvRNn0Meta } from "/opt/buildhome/repo/app/pages/studio/maps/[mapId].vue?macro=true";
import { default as google_45saved_45listsnMktoBPqKhFsjHR_45sYhkwxJCkry2rV_45F_45QQSLAEU91gMeta } from "/opt/buildhome/repo/app/pages/studio/maps/import/google-saved-lists.vue?macro=true";
import { default as mapsoYbqrzQDqRd9P6sGmMoiZmXk9OLOYJUe9DkpcPeUL18Meta } from "/opt/buildhome/repo/app/pages/studio/maps.vue?macro=true";
import { default as indexGPrtspqH5ep7602fl78DroP_1uOyUFq7K8N9haOoGVIMeta } from "/opt/buildhome/repo/app/pages/studio/index.vue?macro=true";
import { default as indexCNdWQNm9gJED_Ula5tcO_Zwr7Hyj3jp4bw6cYXZmRycMeta } from "/opt/buildhome/repo/app/pages/studio/payouts/index.vue?macro=true";
import { default as indexOfVXhFqkMZ_45F0prHkM7XeKj1mdxqmTrXh1bSD_piPN8Meta } from "/opt/buildhome/repo/app/pages/studio/payouts/details/index.vue?macro=true";
import { default as payouts77hojwEugoaSbdkgxbAP_h0dxnGTJRgrF71GCJdOgjYMeta } from "/opt/buildhome/repo/app/pages/studio/payouts.vue?macro=true";
import { default as profileVsmpxWP_45_OqoSeAl36TuDyriGdUO7h9Q8Q4_LfzuvzcMeta } from "/opt/buildhome/repo/app/pages/studio/profile.vue?macro=true";
import { default as supportersrH2x_45aMK2Hi4UQaYe2DFUsDVTmOhUYDydsoQXoKpjrUMeta } from "/opt/buildhome/repo/app/pages/studio/supporters.vue?macro=true";
import { default as index_45NM1IV_45QnRotG5Y0i16ml1_45w3RrBuPsmUwU_IRxZdzUMeta } from "/opt/buildhome/repo/app/pages/studio/admin/index.vue?macro=true";
import { default as indexhuOXNBpyjNA__45nGLBrpFFdyn55J_45SG1sN3Qprz2xDtgMeta } from "/opt/buildhome/repo/app/pages/studio/posts/index.vue?macro=true";
import { default as map_45is_45readyRdGT_WO7J0ocSoCuymdZ6ajXIzeCDoaS2Z1_45mgcbPPIMeta } from "/opt/buildhome/repo/app/pages/studio/map-is-ready.vue?macro=true";
import { default as indexQnEoDS8Dq45_45WA6U_pPSCAe8jx0F2qfo7OciP36k1aEMeta } from "/opt/buildhome/repo/app/pages/studio/creator-faq/index.vue?macro=true";
import { default as _91id_93_45gK7xTYrXMzENx6AENE2dbLGoeo9QsrJYKezTSM7KsYMeta } from "/opt/buildhome/repo/app/pages/studio/admin/creators/[id].vue?macro=true";
import { default as indexgSvjuaP6fuSvZDDpbF0wUD8r0n3wJ6Uu3uVnBFL_ugYMeta } from "/opt/buildhome/repo/app/pages/studio/admin/articles/index.vue?macro=true";
import { default as indexceS6RzJWV92GUc3QulFfQHxExxn3ACBLxeCSucnN8_45UMeta } from "/opt/buildhome/repo/app/pages/studio/admin/creators/index.vue?macro=true";
import { default as _91postId_93CNSM58fIxnP7ho7kBi_45cgWjsz29O3CT_45pg5lvDiHXjIMeta } from "/opt/buildhome/repo/app/pages/studio/posts/postId/[postId].vue?macro=true";
import { default as indexn_ILhW1RekoZsVFgpx0G75q7vybzeMuIf4kKiYRj9cEMeta } from "/opt/buildhome/repo/app/pages/studio/admin/categories/index.vue?macro=true";
import { default as index5QtdBsGm3JO_45CpA_LHAhQYrDGlpk_45jP5VYMfvTQz0l8Meta } from "/opt/buildhome/repo/app/pages/studio/admin/destinations/index.vue?macro=true";
import { default as indexCGNnzV5Y8kMUHKrpVAKqQRhXuYIPuVjXm_Q_oaOasHcMeta } from "/opt/buildhome/repo/app/pages/studio/admin/creator-collab/index.vue?macro=true";
import { default as studioJ2U7ZobiPXrpLf00Xq43ZYUYaUKFHPMR86jgUs9RKfwMeta } from "/opt/buildhome/repo/app/pages/studio.vue?macro=true";
import { default as accountIVawZmTXu_0vEqmSe993_Rp0aLcehNhJRwHj7OqSI5cMeta } from "/opt/buildhome/repo/app/pages/account.vue?macro=true";
import { default as confirmRtcAnrQ3U2aqTj7tA8jvkz9VX4GvnJjMBGwSMckCewEMeta } from "/opt/buildhome/repo/app/pages/confirm.vue?macro=true";
import { default as explorekojBa0v3T50LRK7djZGhTNduJ_45lKQDByPGNTJJbcMw4Meta } from "/opt/buildhome/repo/app/pages/explore.vue?macro=true";
import { default as indexhe9DogRgNkeNyFENW5h13479F_45zMqhEuFHb1k0n3_V0Meta } from "/opt/buildhome/repo/app/pages/maps/index.vue?macro=true";
import { default as saved_45mapsxWML6wiYm6NMoTkPR1LAqGEBNA_lwStyosRBc_45tOa2cMeta } from "/opt/buildhome/repo/app/pages/saved-maps.vue?macro=true";
import { default as saved_45spotsSz_45TgaEZL0mKKZc3mcaDoXUMCyR_45kf3ZeK_8hVb_45F2YMeta } from "/opt/buildhome/repo/app/pages/saved-spots.vue?macro=true";
import { default as _91spotId_938VQv3wj_PlPt_45PpuIeJ9hCvWfWmO_C9_8GXZ_45LsZW_45oMeta } from "/opt/buildhome/repo/app/pages/maps/[mapId]/spots/[spotId].vue?macro=true";
import { default as _91mapId_938b9PeAbUKp8ZBLPI5XT4OJdcowmTIF4xa8bmPJ_45M5C0Meta } from "/opt/buildhome/repo/app/pages/maps/[mapId].vue?macro=true";
import { default as indexn_45nAa2Kd4UCPYwzegG64QxMxaFi6GnHslfx690Of5YYMeta } from "/opt/buildhome/repo/app/pages/creators/index.vue?macro=true";
import { default as copyright6zfb6dxyi8j_45DIjogz5td5YHi6cDaXA67zPnCb6TLMYMeta } from "/opt/buildhome/repo/app/pages/info/copyright.vue?macro=true";
import { default as reset_45passwordM9KP59MP5zfJuGzxFbrRnSJPjJ4Ofz1sYT9607VpLzUMeta } from "/opt/buildhome/repo/app/pages/reset-password.vue?macro=true";
import { default as forgot_45passwordx7ltq_45adgcZxq_453I5xxOKO67_45gQQ1dalvzECVeoFSPUMeta } from "/opt/buildhome/repo/app/pages/forgot-password.vue?macro=true";
import { default as _91handle_93TlBhtTehNhSGkoEjbJ32rktSRZqy_Wmq_lmXtVvyCEQMeta } from "/opt/buildhome/repo/app/pages/creators/[handle].vue?macro=true";
import { default as _91id_93Qs3OWMqnr356zF_XtQzDC5d9Dm6iz_yiuLa5h2XCBH0Meta } from "/opt/buildhome/repo/app/pages/destinations/[id].vue?macro=true";
import { default as legal_45noticecV1QWPi6f_oHPChqZq_9eoPQ9CHfDY_45ZGsSQtPpz_88Meta } from "/opt/buildhome/repo/app/pages/info/legal-notice.vue?macro=true";
import { default as indexVFU9eOgVMF9ClZEnTmxM_45Ochyf7g7TCjKp3tQJOwxgIMeta } from "/opt/buildhome/repo/app/pages/destinations/index.vue?macro=true";
import { default as cookie_45policyI9BPDx1GG9eCD9tks5AE2HjVlSexTadf_NYw91u2np0Meta } from "/opt/buildhome/repo/app/pages/info/cookie-policy.vue?macro=true";
import { default as creator_45termsBJSZSAnuiHB8ANAtO9AqFWMuAurAmFUzvvuYzf4COIUMeta } from "/opt/buildhome/repo/app/pages/info/creator-terms.vue?macro=true";
import { default as privacy_45policyU6WaMT35BLh8gWsOUhXe7eFuOi0UP1_45_246oxv5GS94Meta } from "/opt/buildhome/repo/app/pages/info/privacy-policy.vue?macro=true";
import { default as creator_45compensationgBMCiQ1yd9Ll6WqzPPYlKq_45AC_z_Y6OD3Dq_Vs80wTQMeta } from "/opt/buildhome/repo/app/pages/info/creator-compensation.vue?macro=true";
import { default as _91handle_93vQfo3svgaWHKf07Dy3bjJkIB5GDr_hXPXC_4jGs5XI8Meta } from "~/pages/creators/[handle].vue?macro=true";
import { default as component_45stuby7LfiszNctwelReXwlj04jhJsvABbmkSD8o767dZXNYMeta } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_eslint@9.23.0_rollup@4.38.0_typescript@5.8.2_vite@6.2.4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stuby7LfiszNctwelReXwlj04jhJsvABbmkSD8o767dZXNY } from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_eslint@9.23.0_rollup@4.38.0_typescript@5.8.2_vite@6.2.4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "auth___en",
    path: "/auth",
    component: () => import("/opt/buildhome/repo/app/pages/auth.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/opt/buildhome/repo/app/pages/index.vue")
  },
  {
    name: studioJ2U7ZobiPXrpLf00Xq43ZYUYaUKFHPMR86jgUs9RKfwMeta?.name,
    path: "/studio",
    meta: studioJ2U7ZobiPXrpLf00Xq43ZYUYaUKFHPMR86jgUs9RKfwMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/studio.vue"),
    children: [
  {
    name: mapsoYbqrzQDqRd9P6sGmMoiZmXk9OLOYJUe9DkpcPeUL18Meta?.name,
    path: "maps",
    component: () => import("/opt/buildhome/repo/app/pages/studio/maps.vue"),
    children: [
  {
    name: "studio-maps___en",
    path: "",
    component: () => import("/opt/buildhome/repo/app/pages/studio/maps/index.vue")
  },
  {
    name: _91mapId_939x1V1W4YOqRGVSdF7kVD1mCmSFI5gky5FxTosZvRNn0Meta?.name,
    path: ":mapId()",
    component: () => import("/opt/buildhome/repo/app/pages/studio/maps/[mapId].vue"),
    children: [
  {
    name: "studio-maps-mapId___en",
    path: "",
    meta: index81hg42RcriHmxPE9Lo_45qe_5yFu2A9JiDsjHTi_45dS4dwMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/studio/maps/[mapId]/index.vue")
  },
  {
    name: "studio-maps-mapId-spots-spotId___en",
    path: "spots/:spotId()",
    component: () => import("/opt/buildhome/repo/app/pages/studio/maps/[mapId]/spots/[spotId].vue")
  }
]
  },
  {
    name: "studio-maps-import-google-saved-lists___en",
    path: "import/google-saved-lists",
    component: () => import("/opt/buildhome/repo/app/pages/studio/maps/import/google-saved-lists.vue")
  }
]
  },
  {
    name: "studio___en",
    path: "",
    component: () => import("/opt/buildhome/repo/app/pages/studio/index.vue")
  },
  {
    name: payouts77hojwEugoaSbdkgxbAP_h0dxnGTJRgrF71GCJdOgjYMeta?.name,
    path: "payouts",
    component: () => import("/opt/buildhome/repo/app/pages/studio/payouts.vue"),
    children: [
  {
    name: "studio-payouts___en",
    path: "",
    component: () => import("/opt/buildhome/repo/app/pages/studio/payouts/index.vue")
  },
  {
    name: "studio-payouts-details___en",
    path: "details",
    component: () => import("/opt/buildhome/repo/app/pages/studio/payouts/details/index.vue")
  }
]
  },
  {
    name: "studio-profile___en",
    path: "profile",
    component: () => import("/opt/buildhome/repo/app/pages/studio/profile.vue")
  },
  {
    name: "studio-supporters___en",
    path: "supporters",
    component: () => import("/opt/buildhome/repo/app/pages/studio/supporters.vue")
  },
  {
    name: "studio-admin___en",
    path: "admin",
    component: () => import("/opt/buildhome/repo/app/pages/studio/admin/index.vue")
  },
  {
    name: "studio-posts___en",
    path: "posts",
    component: () => import("/opt/buildhome/repo/app/pages/studio/posts/index.vue")
  },
  {
    name: "studio-map-is-ready___en",
    path: "map-is-ready",
    component: () => import("/opt/buildhome/repo/app/pages/studio/map-is-ready.vue")
  },
  {
    name: "studio-creator-faq___en",
    path: "creator-faq",
    component: () => import("/opt/buildhome/repo/app/pages/studio/creator-faq/index.vue")
  },
  {
    name: "studio-admin-creators-id___en",
    path: "admin/creators/:id()",
    component: () => import("/opt/buildhome/repo/app/pages/studio/admin/creators/[id].vue")
  },
  {
    name: "studio-admin-articles___en",
    path: "admin/articles",
    component: () => import("/opt/buildhome/repo/app/pages/studio/admin/articles/index.vue")
  },
  {
    name: "studio-admin-creators___en",
    path: "admin/creators",
    component: () => import("/opt/buildhome/repo/app/pages/studio/admin/creators/index.vue")
  },
  {
    name: "studio-posts-postId-postId___en",
    path: "posts/postId/:postId()",
    component: () => import("/opt/buildhome/repo/app/pages/studio/posts/postId/[postId].vue")
  },
  {
    name: "studio-admin-categories___en",
    path: "admin/categories",
    component: () => import("/opt/buildhome/repo/app/pages/studio/admin/categories/index.vue")
  },
  {
    name: "studio-admin-destinations___en",
    path: "admin/destinations",
    component: () => import("/opt/buildhome/repo/app/pages/studio/admin/destinations/index.vue")
  },
  {
    name: "studio-admin-creator-collab___en",
    path: "admin/creator-collab",
    component: () => import("/opt/buildhome/repo/app/pages/studio/admin/creator-collab/index.vue")
  }
]
  },
  {
    name: "account___en",
    path: "/account",
    meta: accountIVawZmTXu_0vEqmSe993_Rp0aLcehNhJRwHj7OqSI5cMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/account.vue")
  },
  {
    name: "confirm___en",
    path: "/confirm",
    component: () => import("/opt/buildhome/repo/app/pages/confirm.vue")
  },
  {
    name: "explore___en",
    path: "/explore",
    component: () => import("/opt/buildhome/repo/app/pages/explore.vue")
  },
  {
    name: "maps___en",
    path: "/maps",
    component: () => import("/opt/buildhome/repo/app/pages/maps/index.vue")
  },
  {
    name: "saved-maps___en",
    path: "/saved-maps",
    meta: saved_45mapsxWML6wiYm6NMoTkPR1LAqGEBNA_lwStyosRBc_45tOa2cMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/saved-maps.vue")
  },
  {
    name: "saved-spots___en",
    path: "/saved-spots",
    meta: saved_45spotsSz_45TgaEZL0mKKZc3mcaDoXUMCyR_45kf3ZeK_8hVb_45F2YMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/saved-spots.vue")
  },
  {
    name: "maps-mapId___en",
    path: "/maps/:mapId()",
    component: () => import("/opt/buildhome/repo/app/pages/maps/[mapId].vue"),
    children: [
  {
    name: "maps-mapId-spots-spotId___en",
    path: "spots/:spotId()",
    component: () => import("/opt/buildhome/repo/app/pages/maps/[mapId]/spots/[spotId].vue")
  }
]
  },
  {
    name: "creators___en",
    path: "/creators",
    component: () => import("/opt/buildhome/repo/app/pages/creators/index.vue")
  },
  {
    name: "info-copyright___en",
    path: "/info/copyright",
    component: () => import("/opt/buildhome/repo/app/pages/info/copyright.vue")
  },
  {
    name: "reset-password___en",
    path: "/reset-password",
    component: () => import("/opt/buildhome/repo/app/pages/reset-password.vue")
  },
  {
    name: "forgot-password___en",
    path: "/forgot-password",
    component: () => import("/opt/buildhome/repo/app/pages/forgot-password.vue")
  },
  {
    name: "creators-handle___en",
    path: "/creators/:handle()",
    component: () => import("/opt/buildhome/repo/app/pages/creators/[handle].vue")
  },
  {
    name: "destinations-id___en",
    path: "/destinations/:id()",
    component: () => import("/opt/buildhome/repo/app/pages/destinations/[id].vue")
  },
  {
    name: "info-legal-notice___en",
    path: "/info/legal-notice",
    component: () => import("/opt/buildhome/repo/app/pages/info/legal-notice.vue")
  },
  {
    name: "destinations___en",
    path: "/destinations",
    component: () => import("/opt/buildhome/repo/app/pages/destinations/index.vue")
  },
  {
    name: "info-cookie-policy___en",
    path: "/info/cookie-policy",
    component: () => import("/opt/buildhome/repo/app/pages/info/cookie-policy.vue")
  },
  {
    name: "info-creator-terms___en",
    path: "/info/creator-terms",
    component: () => import("/opt/buildhome/repo/app/pages/info/creator-terms.vue")
  },
  {
    name: "info-privacy-policy___en",
    path: "/info/privacy-policy",
    component: () => import("/opt/buildhome/repo/app/pages/info/privacy-policy.vue")
  },
  {
    name: "info-creator-compensation___en",
    path: "/info/creator-compensation",
    component: () => import("/opt/buildhome/repo/app/pages/info/creator-compensation.vue")
  },
  {
    name: "profile___en",
    path: "/@:handle",
    component: () => import("~/pages/creators/[handle].vue")
  },
  {
    name: component_45stuby7LfiszNctwelReXwlj04jhJsvABbmkSD8o767dZXNYMeta?.name,
    path: "/search",
    component: component_45stuby7LfiszNctwelReXwlj04jhJsvABbmkSD8o767dZXNY
  },
  {
    name: component_45stuby7LfiszNctwelReXwlj04jhJsvABbmkSD8o767dZXNYMeta?.name,
    path: "/app/:pathMatch(.*)",
    component: component_45stuby7LfiszNctwelReXwlj04jhJsvABbmkSD8o767dZXNY
  },
  {
    name: component_45stuby7LfiszNctwelReXwlj04jhJsvABbmkSD8o767dZXNYMeta?.name,
    path: "/app/account",
    component: component_45stuby7LfiszNctwelReXwlj04jhJsvABbmkSD8o767dZXNY
  },
  {
    name: component_45stuby7LfiszNctwelReXwlj04jhJsvABbmkSD8o767dZXNYMeta?.name,
    path: "/app/bookmarks",
    component: component_45stuby7LfiszNctwelReXwlj04jhJsvABbmkSD8o767dZXNY
  },
  {
    name: component_45stuby7LfiszNctwelReXwlj04jhJsvABbmkSD8o767dZXNYMeta?.name,
    path: "/studio/bank",
    component: component_45stuby7LfiszNctwelReXwlj04jhJsvABbmkSD8o767dZXNY
  },
  {
    name: component_45stuby7LfiszNctwelReXwlj04jhJsvABbmkSD8o767dZXNYMeta?.name,
    path: "/sitemap.xml",
    component: component_45stuby7LfiszNctwelReXwlj04jhJsvABbmkSD8o767dZXNY
  }
]