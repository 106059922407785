import { defineNuxtPlugin } from '#app/nuxt'
import { LazyMPAccordion, LazyMPAddSpot, LazyMPAppPageHeader, LazyMPAuth, LazyMPCreatorOnboarding, LazyMPLogin, LazyMPSignUp, LazyMPUserOnboarding, LazyMPAutoComplete, LazyMPBookmark, LazyMPButton, LazyMPCarousel, LazyMPCategoryCard, LazyMPCheckEmail, LazyMPCheckbox, LazyMPCheckout, LazyMPCollaborateForm, LazyMPConfetti, LazyMPCopyRightText, LazyMPCreatorTag, LazyMPCreatorsHero, LazyMPDialog, LazyMPEditable, LazyMPEmptyState, LazyMPFollow, LazyMPFooter, LazyMPHeader, LazyMPHero, LazyMPImageCropper, LazyMPImg, LazyMPInput, LazyMPLoader, LazyMPLogo, LazyMPMap, LazyMPMapCard, LazyMPMessage, LazyMPPricing, LazyMPRangeSlider, LazyMPSearchInline, LazyMPSectionHeader, LazyMPSelect, LazyMPShare, LazyMPSocialShare, LazyMPSkewContainer, LazyMPSpotCard, LazyMPSpotTierOverlay, LazyMPStack, LazyMPTable, LazyMPTag, LazyMPTextarea, LazyMPTitleRolodex, LazyMPToast, LazyMPUnlockCta, LazyMPViewportSpill, LazyMPDefault, LazyBrandedLogo, LazyFrame, LazyNuxt, LazyNuxtSeo, LazyPergel, LazySimpleBlog, LazyUnJs, LazyWave, LazyWithEmoji } from '#components'
const lazyGlobalComponents = [
  ["MPAccordion", LazyMPAccordion],
["MPAddSpot", LazyMPAddSpot],
["MPAppPageHeader", LazyMPAppPageHeader],
["MPAuth", LazyMPAuth],
["MPCreatorOnboarding", LazyMPCreatorOnboarding],
["MPLogin", LazyMPLogin],
["MPSignUp", LazyMPSignUp],
["MPUserOnboarding", LazyMPUserOnboarding],
["MPAutoComplete", LazyMPAutoComplete],
["MPBookmark", LazyMPBookmark],
["MPButton", LazyMPButton],
["MPCarousel", LazyMPCarousel],
["MPCategoryCard", LazyMPCategoryCard],
["MPCheckEmail", LazyMPCheckEmail],
["MPCheckbox", LazyMPCheckbox],
["MPCheckout", LazyMPCheckout],
["MPCollaborateForm", LazyMPCollaborateForm],
["MPConfetti", LazyMPConfetti],
["MPCopyRightText", LazyMPCopyRightText],
["MPCreatorTag", LazyMPCreatorTag],
["MPCreatorsHero", LazyMPCreatorsHero],
["MPDialog", LazyMPDialog],
["MPEditable", LazyMPEditable],
["MPEmptyState", LazyMPEmptyState],
["MPFollow", LazyMPFollow],
["MPFooter", LazyMPFooter],
["MPHeader", LazyMPHeader],
["MPHero", LazyMPHero],
["MPImageCropper", LazyMPImageCropper],
["MPImg", LazyMPImg],
["MPInput", LazyMPInput],
["MPLoader", LazyMPLoader],
["MPLogo", LazyMPLogo],
["MPMap", LazyMPMap],
["MPMapCard", LazyMPMapCard],
["MPMessage", LazyMPMessage],
["MPPricing", LazyMPPricing],
["MPRangeSlider", LazyMPRangeSlider],
["MPSearchInline", LazyMPSearchInline],
["MPSectionHeader", LazyMPSectionHeader],
["MPSelect", LazyMPSelect],
["MPShare", LazyMPShare],
["MPSocialShare", LazyMPSocialShare],
["MPSkewContainer", LazyMPSkewContainer],
["MPSpotCard", LazyMPSpotCard],
["MPSpotTierOverlay", LazyMPSpotTierOverlay],
["MPStack", LazyMPStack],
["MPTable", LazyMPTable],
["MPTag", LazyMPTag],
["MPTextarea", LazyMPTextarea],
["MPTitleRolodex", LazyMPTitleRolodex],
["MPToast", LazyMPToast],
["MPUnlockCta", LazyMPUnlockCta],
["MPViewportSpill", LazyMPViewportSpill],
["MPDefault", LazyMPDefault],
["BrandedLogo", LazyBrandedLogo],
["Frame", LazyFrame],
["Nuxt", LazyNuxt],
["NuxtSeo", LazyNuxtSeo],
["Pergel", LazyPergel],
["SimpleBlog", LazySimpleBlog],
["UnJs", LazyUnJs],
["Wave", LazyWave],
["WithEmoji", LazyWithEmoji],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
