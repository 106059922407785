import { defineNuxtPlugin } from '#imports';

declare global {
  interface Window {
    // biome-ignore lint/suspicious/noExplicitAny:
    Fernand: any;
  }
}

export default defineNuxtPlugin(() => {
  if (import.meta.client && !import.meta.dev) {
    if (typeof window.Fernand !== 'function') {
      // biome-ignore lint/suspicious/noExplicitAny:
      window.Fernand = (...args: any[]) => {
        if (!window.Fernand.q) {
          window.Fernand.q = [];
        }

        if (args[0] === 'set') {
          window.Fernand.q.unshift(args);
        } else {
          window.Fernand.q.push(args);
        }
      };

      window.Fernand.q = [];
    }

    window.Fernand('init', {
      appId: 'mappr',
      orgName: 'Mappr.com',
      sound: false,
      tabs: [
        // 'home',
        'chat',
        // 'contact'
      ],
      theme: 'light',
      accentColor: 'var(--color-rose-400)',
      iconColor: 'var(--color-rose-50)',
    });

    const script = document.createElement('script');
    script.src = 'https://messenger.getfernand.com/client.js';
    script.async = true;
    document.head.appendChild(script);

    return {
      provide: {
        fernand: () => window.Fernand,
      },
    };
  }
});
