import revive_payload_client_NBQQmn7TMFzJ8tsgF5ubHKmSj7vPPIVFKEeucb2w_QA from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_eslint@9.23.0_rollup@4.38.0_typescript@5.8.2_vite@6.2.4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_rUJM7LwlprPwHIeWa9tvAYW9VlmyLppGLtyRwjnIcFk from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_eslint@9.23.0_rollup@4.38.0_typescript@5.8.2_vite@6.2.4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_G1rUv8J5CCdigfZhvoeknJfY6VK8srxicERlEuWawOc from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_eslint@9.23.0_rollup@4.38.0_typescript@5.8.2_vite@6.2.4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_21Yue4F_qX0qZhH9bHG9ZA3g6U3NdZAbP_MQLi32Cvs from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.1.7_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import supabase_client_no0NtLZ9smb9h5JXyu0FjQPCTx_KZfOxFyGtFfOag_g from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_OePk_gYjkKfFbcZ3ZDfraDmu0lkntLlBu8_DSU6HTfI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_eslint@9.23.0_rollup@4.38.0_typescript@5.8.2_vite@6.2.4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PFqm1vZRBqyLC9Gg54cAa4C2L2DFiZ2XadJMRuDXuKI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_eslint@9.23.0_rollup@4.38.0_typescript@5.8.2_vite@6.2.4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_pLlx99H9GCa6rczzFeHKem4jWCZM0hg6OYaZf3LFoNQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_eslint@9.23.0_rollup@4.38.0_typescript@5.8.2_vite@6.2.4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_pfEkF8XpuRBIsG8Mp0t7j1_HJuh6Te8PuCJbifCQ7X0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_eslint@9.23.0_rollup@4.38.0_typescript@5.8.2_vite@6.2.4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_CE5kxh94gFBl4CoNxNjooaa8_Z_vmMGowP005H1nfPs from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.16.1_@biomejs+biome@1.9.4_eslint@9.23.0_rollup@4.38.0_typescript@5.8.2_vite@6.2.4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_Ewm_I0dJxLSoLHtuAze0ipYYc7b_RJGRhwVcb3oG9HM from "/opt/buildhome/repo/node_modules/.pnpm/@sentry+nuxt@9.1.0_@opentelemetry+api@1.9.0_@opentelemetry+context-async-hooks@1.30.1_@opente_w5y5ussylseob5whkps5ozjztu/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/opt/buildhome/repo/.nuxt/sentry-client-config.mjs";
import siteConfig_4Jz1tLAetEiHWkZG6K8zEpJVpc8G88sfl_QS567sEyQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@7.0.4_rollup@4.38.0_vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_tBJkubOTmoTFiVT0luPpuTgqgwcgE2rOvsVt2t6I84Q from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@7.0.4_rollup@4.38.0_vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_pWp94_71eY6oHFOwYLe5jbgG_E5BJrHP5WMmXQy3BsE from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@7.0.4_rollup@4.38.0_vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_7lSO0PU3eXfuClhU0Kgb1n9GwyAYuFMCCS_IVoNl_ww from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@7.0.4_rollup@4.38.0_vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import i18n_g6q3GfiwXaOYp_D80j4QfHgsvQEC3d6VUc_GMGo5NxQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@3.1.7_vue@3.5.13/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import switch_locale_path_ssr_F1iwAtQ7CwsANID8GMByN_cMVOlTJYPKGUfdxx_jKyY from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_eslint@9.23.0_rollup@4.38.0_typescript@5.8.2_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_D9kjS8WwRl0TJLr49t0tXq_Ku4nA7Ey_9szZ6yR9o7I from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_eslint@9.23.0_rollup@4.38.0_typescript@5.8.2_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_7vHQVBxqnaR9HmnwG_Gaj0wiVplLphVOAVFeHNhlYkM from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_eslint@9.23.0_rollup@4.38.0_typescript@5.8.2_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import fernand_ESqdJ_lqtTzTaNX6_g57gKPq4Q5ui8pWPce7PzKzVLA from "/opt/buildhome/repo/app/plugins/fernand.ts";
import posthog_L2WhwPMYsQFde4_Sg_Fg_CY7KuXhtI4gfay4nevR2z0 from "/opt/buildhome/repo/app/plugins/posthog.ts";
import scrollTo_FqJ29xkvMdo97PxF3t9YhSq_yJQByRHang_dodJZ5Io from "/opt/buildhome/repo/app/plugins/scrollTo.ts";
import stripe_TXMsq4EGQvvDySskaNUz7vMd5hueM6IjFgWi6SubSMQ from "/opt/buildhome/repo/app/plugins/stripe.ts";
import _0_routeRules_MpX_ZlJnXfgDfiOLiILjzQEEly4njHIySGluke_ZF0M from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-utils@7.0.4_rollup@4.38.0_vue@3.5.13/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/0.routeRules.js";
import ssg_detect_SJI6g783BTj2GFuzFb0NnLQlGazJ_IVWcp02pgWEOlw from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@9.3.2_eslint@9.23.0_rollup@4.38.0_typescript@5.8.2_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_NBQQmn7TMFzJ8tsgF5ubHKmSj7vPPIVFKEeucb2w_QA,
  unhead_rUJM7LwlprPwHIeWa9tvAYW9VlmyLppGLtyRwjnIcFk,
  router_G1rUv8J5CCdigfZhvoeknJfY6VK8srxicERlEuWawOc,
  _0_siteConfig_21Yue4F_qX0qZhH9bHG9ZA3g6U3NdZAbP_MQLi32Cvs,
  supabase_client_no0NtLZ9smb9h5JXyu0FjQPCTx_KZfOxFyGtFfOag_g,
  payload_client_OePk_gYjkKfFbcZ3ZDfraDmu0lkntLlBu8_DSU6HTfI,
  navigation_repaint_client_PFqm1vZRBqyLC9Gg54cAa4C2L2DFiZ2XadJMRuDXuKI,
  check_outdated_build_client_pLlx99H9GCa6rczzFeHKem4jWCZM0hg6OYaZf3LFoNQ,
  chunk_reload_client_pfEkF8XpuRBIsG8Mp0t7j1_HJuh6Te8PuCJbifCQ7X0,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_CE5kxh94gFBl4CoNxNjooaa8_Z_vmMGowP005H1nfPs,
  sentry_client_Ewm_I0dJxLSoLHtuAze0ipYYc7b_RJGRhwVcb3oG9HM,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  siteConfig_4Jz1tLAetEiHWkZG6K8zEpJVpc8G88sfl_QS567sEyQ,
  inferSeoMetaPlugin_tBJkubOTmoTFiVT0luPpuTgqgwcgE2rOvsVt2t6I84Q,
  titles_pWp94_71eY6oHFOwYLe5jbgG_E5BJrHP5WMmXQy3BsE,
  defaultsWaitI18n_7lSO0PU3eXfuClhU0Kgb1n9GwyAYuFMCCS_IVoNl_ww,
  i18n_g6q3GfiwXaOYp_D80j4QfHgsvQEC3d6VUc_GMGo5NxQ,
  switch_locale_path_ssr_F1iwAtQ7CwsANID8GMByN_cMVOlTJYPKGUfdxx_jKyY,
  route_locale_detect_D9kjS8WwRl0TJLr49t0tXq_Ku4nA7Ey_9szZ6yR9o7I,
  i18n_7vHQVBxqnaR9HmnwG_Gaj0wiVplLphVOAVFeHNhlYkM,
  fernand_ESqdJ_lqtTzTaNX6_g57gKPq4Q5ui8pWPce7PzKzVLA,
  posthog_L2WhwPMYsQFde4_Sg_Fg_CY7KuXhtI4gfay4nevR2z0,
  scrollTo_FqJ29xkvMdo97PxF3t9YhSq_yJQByRHang_dodJZ5Io,
  stripe_TXMsq4EGQvvDySskaNUz7vMd5hueM6IjFgWi6SubSMQ,
  _0_routeRules_MpX_ZlJnXfgDfiOLiILjzQEEly4njHIySGluke_ZF0M,
  ssg_detect_SJI6g783BTj2GFuzFb0NnLQlGazJ_IVWcp02pgWEOlw
]