import { ref } from '#imports';

const isOpen = ref(false);
const currentComp = ref<string | null>('');
// biome-ignore lint/suspicious/noExplicitAny:
const currentCompProps = ref<any>({});
const isFullscreen = ref(false);

export const useDialog = () => {
  // biome-ignore lint/suspicious/noExplicitAny:
  const openDialog = (compName: string, compProps?: any) => {
    currentComp.value = compName;
    currentCompProps.value = compProps;
    isOpen.value = true;
  };

  const closeDialog = () => {
    isOpen.value = false;
    isFullscreen.value = false;
    currentComp.value = null;
    currentCompProps.value = {};
  };

  const setDialogFullscreen = (value: boolean) => {
    isFullscreen.value = value;
  };

  return {
    isOpen,
    currentComp,
    currentCompProps,
    openDialog,
    closeDialog,
    isFullscreen,
    setDialogFullscreen,
  };
};
