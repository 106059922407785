<script setup lang="ts">
import { useToast } from '#imports';
const { toasts } = useToast();
</script>

<template>
  <div class="toast-container">
    <TransitionGroup v-if="toasts" name="fade">
      <div
        v-for="(toast, idx) in toasts"
        :key="toast.id"
        :class="`toast ${toast.type} ${idx}`"
      >
        <strong>{{ toast.type }}</strong>
        <span>{{ toast.message }}</span>
      </div>
    </TransitionGroup>
  </div>
</template>

<style scoped>
.toast-container {
  position: fixed;
  bottom: 4rem;
  right: 50%;
  transform: translateX(50%);
  z-index: 8000;
  display: grid;
  gap: 0.5rem;
}

.toast {
  padding: 0.5rem 1.5rem 0.5rem 0.75rem;
  color: var(--color-black);
  z-index: 8000;
  background-color: white;
  box-shadow: var(--shadow-lg);
  border: 1px solid var(--color-slate-50);
  border-radius: 0.25rem;
}

.toast + .toast {
  position: relative;
}

.toast strong {
  display: block;
  text-transform: capitalize;
  font-size: 0.9rem;
  line-height: 1.2;
}

.toast span {
  font-size: 0.8rem;
}

.info strong { color: #2196F3; }
.success strong { color: #4CAF50; }
.error strong { color: #F44336; }
.warning strong { color: #FFC107; }

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.45s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
