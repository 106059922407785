<script setup lang="ts">
import { onNuxtReady, ref } from '#imports';
type ButtonSize = 'small' | 'medium' | 'large';
type ButtonVersion =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'save'
  | 'info'
  | 'danger';
type ButtonType = 'submit' | 'reset' | 'button';
const props = withDefaults(
  defineProps<{
    label?: string | null;
    icon?: string | null;
    iconColor?: string | null;
    ariaLabel?: string | null;
    rounded?: boolean;
    size?: ButtonSize;
    version?: ButtonVersion;
    outline?: boolean;
    block?: boolean;
    link?: boolean;
    tight?: boolean;
    backgroundColor?: string;
    inline?: boolean;
    hideLabel?: boolean;
    disabled?: boolean;
    type?: ButtonType;
    isLoading?: boolean;
  }>(),
  {
    type: 'button',
    // backgroundColor: 'inherit',
    version: 'default',
    size: 'medium',
    icon: null,
    iconColor: null,
    label: null,
    ariaLabel: null,
  },
);
const appLoaded = ref<boolean>(false);
onNuxtReady(() => {
  appLoaded.value = true;
});
</script>

<template>
  <button
    class="mp-button"
    :class="`
      ${props.size ? props.size : 'medium' }
      ${props.rounded ? 'rounded' : '' }
      ${props.outline ? 'outline' : '' }
      ${props.circle ? 'circle' : '' }
      ${props.block ? 'block' : '' }
      ${props.link ? 'link' : '' }
      ${props.tight ? 'tight' : '' }
      ${props.version ?? 'default' }
      ${props.icon && props.hideLabel ? 'icon-only' : '' }
      ${props.inline ? 'inline' : '' }
      ${props.backgroundColor === 'transparent' ? 'no-border' : '' }
      ${props.disabled ? 'disabled' : '' }
      ${props.noBorder ? 'no-border' : '' }
    `"
    :aria-label="props.ariaLabel ?? props.label"
    :style="`background-color: ${props.backgroundColor}; color: ${props.iconColor}`"
    :type="props.type"
    :disabled="props.disabled || !appLoaded"
  >
    <i v-if="props.icon" :class="`${props.icon} icon`" />
    <label v-if="!props.hideLabel && props.label">
      <Transition name="fade">
        <span v-if="props.isLoading">
          <i v-if="props.isLoading" class="ri-loader-4-line loading-indicator" />
          &nbsp;
        </span>
        <span v-else>
          {{ props.label }}
        </span>
      </Transition>
    </label>
    <slot />
  </button>
</template>

<style lang="postcss" scoped>
@import '@/assets/css/typography.pcss';
@import '@/assets/css/breakpoints.pcss';

.mp-button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: 0 none;
  border: 0 none;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 0.5rem;
  margin: 0;
  padding: 0.5rem 1rem;
  @mixin typeface-summary;
  font-family: 'Hanken Grotesk' !important;
  transition: opacity 100ms ease-in;
  label {
    cursor: pointer;
  }
  i {
    cursor: pointer;
    font-size: 1rem;
  }
  .icon + label {
    margin-left: 0.5rem;
  }
  &.disabled,
  &:disabled {
    opacity: 0.2 !important;
    cursor: not-allowed;
    transition: opacity 100ms ease-in;
    label {
      cursor: not-allowed;
    }
  }
  &.rounded {
    border-radius: 2em;
  }
  &.xs {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }
  &.small {
    font-size: 0.875rem;
    padding: 0.25rem 0.75em;
  }
  &.medium {
    padding: 0.6rem 1.5em;
    @mixin screen xs {
      font-size: 1rem;
      padding: 0.5rem 1.25rem;
    }
  }
  &.large {
    padding: 0.75rem 1.75rem;
    font-size: 1.1rem;
  }
  &.tight {
    padding: 0.25rem 0.55rem;
  }
  &.default {
    background-color: var(--color-slate-900);
    color: white;
    border: 1px solid var(--color-slate-800);
    &:hover {
      background-color: var(--color-slate-800);
    }
  }
  &.secondary {
    background-color: var(--color-slate-50);
    color: var(--color-slate-900);
    border: 1px solid var(--color-slate-100);
    &:hover {
      background-color: var(--color-slate-100)
    }
  }
  &.primary {
    background-color: var(--color-rose-500);
    color: white;
    &:hover {
      background-color: var(--color-rose-600)
    }
  }
  &.save {
    background-color: var(--color-green-500);
    color: white;
    &:hover {
      background-color: var(--color-green-600)
    }
  }
  &.info {
    background-color: var(--color-indigo-500);
    color: white;
    &:hover {
      background-color: var(--color-indigo-600)
    }
  }
  &.danger {
    background-color: var(--color-rose-500);
    color: white;
    &:hover {
      background-color: var(--color-rose-600)
    }
  }
}

.link {
  &.default {
    background-color: transparent;
    border: 1px solid transparent;
    color: var(--color-slate-600);
    &:hover {
      color: var(--color-slate-800);
    }
  }
  &.secondary {
    background-color: transparent;
    border: 1px solid transparent;
    color: var(--color-slate-400);
  }
  &.primary {
    background-color: transparent;
    border: 1px solid transparent;
    color: var(--color-rose-400);
  }
  &.save {
    background-color: transparent;
    border: 1px solid transparent;
    color: var(--color-green-400);
  }
  &.info {
    background-color: transparent;
    border: 1px solid transparent;
    color: var(--color-indigo-400);
  }
  &.danger {
    background-color: transparent;
    border: 1px solid transparent;
    color: var(--color-rose-500);
  }
}

.inline {
  padding-left: 0;
  padding-right: 0;
}

.outline {
  background-color: transparent;
  &.default {
    background-color: transparent;
    color: var(--color-slate-900);
    border: 1px solid var(--color-slate-900);
  }
  &.secondary {
    background-color: transparent;
    color: var(--color-slate-50);
    border: 1px solid var(--color-slate-50);
  }
  &.primary {
    background-color: transparent;
    color: var(--color-rose-400);
    border: 1px solid var(--color-rose-400);
  }
  &.save {
    background-color: transparent;
    color: var(--color-green-400);
    border: 1px solid var(--color-green-400);
  }
  &.info {
    background-color: transparent;
    color: var(--color-indigo-400);
    border: 1px solid var(--color-indigo-400);
  }
  &.danger {
    background-color: transparent;
    color: var(--color-rose-500);
    border: 1px solid var(--color-rose-500);
  }
}


.icon-only,
.icon-only.small,
.icon-only.large {
  display: block;
  position: relative;
  padding: 0.5rem 0.65rem !important;
  width: 2.5rem;
  height: 2.5rem;
  i {
    font-size: 1.25rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.icon-only.small {
  width: 2rem;
  height: 2rem;
  i {
    font-size: 1.25rem;
  }
}


.icon-only.large {
  width: 3rem;
  height: 3rem;
  i {
    font-size: 2.75rem;
  }
}

.circle {
  border-radius: 100%;
  position: relative;
  &.small i {
    font-size: 1.25rem !important;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 3px;
  }
  &.medium i {
    font-size: 1.5rem !important;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 3px;
  }
  &.large i {
    font-size: 2.1rem !important;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 3px;
  }
}

.no-border {
  border: none !important;
}

.loading-indicator {
  position: absolute;
  top: 0.45rem;
  left: calc(50% - 0.5rem);
  animation: spin 2s linear infinite;
  font-size: 1.5rem !important;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
