<script lang="ts" setup>
import { useStorage, computed } from '#imports';
const { formatedImageUrl } = useStorage();
const props = defineProps({
  src: {
    type: String,
    required: false,
    default: '/media/images/placeholder.jpg',
  },
  width: {
    type: Number,
    default: 800,
  },
  height: {
    type: Number,
    default: 600,
  },
  quality: {
    type: Number,
    default: 75,
  },
  resize: {
    type: String,
    default: 'cover',
  },
  overlay: {
    type: Boolean,
    default: false,
  },
});

const imageSrc = computed(() =>
  formatedImageUrl(
    props.src,
    props.width,
    props.height,
    props.quality,
    props.resize,
  ),
);

// const containerClasses = computed(() => ({
//   'image-container': true,
//   'has-overlay': props.overlay
// }))
</script>

<template>
  <img
    loading="lazy"
    :src="imageSrc"
    class="image"
  >
</template>

<style lang="postcss" scoped>
.image-container {
  position: relative;
  display: inline-block;

  &.has-overlay::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    pointer-events: none;
  }
}

.image {
  max-width: 100%;
  display: block;
}
</style>
