import { useAuth, useNuxtApp, useState, useToast } from '#imports';

interface MetricCustomProperties {
  map_name?: string | null;
  map_id?: string | null;
  creator_handle?: string | null;
  creator_id?: string | null;
  spot_id?: string | null;
  spot_name?: string;
  spot_destination?: string;
  spot_category?: string;
  countries?: string[];
  attribution_last_click?: string;
  product?: string;
  product_price?: string;
  product_id?: string;
  product_period?: string;
  payment_amount?: number;
  payment_currency?: string;
  subscription_tier?: string;
}

export const useMetrics = () => {
  const { currentUser } = useAuth();
  const { logger } = useToast();
  const { $posthog } = useNuxtApp();

  const lastSeenCreator = useState<string | null>(
    'lastSeenCreator',
    () => null,
  );

  const trackEvent = (event: string, properties?: MetricCustomProperties) => {
    if (properties?.creator_handle) {
      lastSeenCreator.value = properties.creator_handle;
    }
    const propsSchema = {
      is_authenticated: currentUser.value !== null,
      ...properties,
      ...(lastSeenCreator.value && {
        attribution_last_click: lastSeenCreator.value,
      }),
    };

    if (import.meta.dev) {
      console.log(
        '⏩ Events Disabled in Development mode event details: ',
        event,
      );
      return;
    }
    try {
      $posthog().capture(event, propsSchema);
    } catch (e) {
      /*
       * if the events server is down we just drop the events to prevent excessive errors
       */
      logger('failed to track user event', e);
    }
  };

  return {
    trackEvent,
  };
};
